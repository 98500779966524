import React from "react"
import { Link } from "gatsby"

import ScrollTransition from "../ScrollTransition"

export default function HeaderLogo(props) {
  return (
    <div className="HeaderLogo" data-cursor-non-label>
      <ScrollTransition delay={0.55}>
        <Link to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="90.551" height="101.205" viewBox="0 0 90.551 101.205" className="color-invert">
            <g className="mark">
              <polygon points="47.005 18.41 25.685 18.41 19.908 30.011 41.042 30.011 47.005 18.41" fill="#fff"/>
              <path d="M73.277,16.852c-1.272,0-2.522.097-3.743.281L77.816.14h-13.48l-24.061,47.171-.006.009c6.659.876,11.8,6.569,11.8,13.467,0,7.504-6.084,13.588-13.588,13.588-6.945,0-12.669-5.212-13.485-11.938h-11.351c.85,12.979,11.641,23.246,24.836,23.246,12.498,0,22.842-9.209,24.623-21.212,1.911.857,3.95,1.48,6.079,1.834l5.624-11.057c-.503.056-1.013.088-1.531.088-7.504,0-13.588-6.084-13.588-13.588s6.083-13.588,13.588-13.588c5.062,0,9.474,2.77,11.812,6.875l5.622-11.053c-4.492-4.408-10.644-7.13-17.435-7.13Z" fill="#fff"/>
            </g>
            <g className="twenty">
              <path d="M6.41,75.422c.314.141.616.283.909.427.292.144.595.287.908.427.306.138.579.256.821.354.242.098.465.169.669.215.205.045.395.063.571.052s.354-.057.534-.138c.234-.106.397-.26.486-.462s.083-.417-.021-.646c-.124-.273-.282-.459-.475-.559s-.479-.111-.857-.031l-.373-1.173c.257-.057.511-.087.764-.089.252-.002.495.041.729.129.234.088.453.234.657.438.204.204.387.486.551.846.286.633.348,1.184.185,1.652-.163.468-.512.823-1.047,1.065-.229.104-.454.171-.673.201-.22.03-.448.03-.686,0-.238-.031-.491-.092-.759-.184-.269-.092-.568-.212-.897-.359-.168-.082-.338-.165-.507-.249-.17-.084-.36-.171-.571-.259l1.052,2.325-1.054.329-1.774-3.922.86-.389Z" fill="#fff"/>
              <path d="M10.043,83.978c-.46-.584-.61-1.2-.449-1.848.16-.649.683-1.321,1.568-2.018.442-.348.859-.606,1.25-.773s.756-.252,1.096-.257.651.069.934.22c.283.151.537.37.763.657.46.584.608,1.201.445,1.851s-.684,1.322-1.564,2.016c-.885.697-1.663,1.045-2.335,1.044-.672,0-1.241-.298-1.708-.891ZM10.91,83.307c.122.155.264.255.425.3.161.045.342.042.542-.009.2-.051.416-.145.648-.281.232-.137.482-.311.75-.522.268-.211.496-.414.683-.607.187-.193.329-.381.425-.564.096-.183.142-.357.136-.525-.006-.167-.07-.328-.192-.484s-.263-.254-.422-.296c-.159-.042-.338-.037-.536.016-.198.053-.414.148-.645.285-.232.137-.482.311-.75.522-.268.211-.496.414-.683.607s-.33.38-.428.56c-.098.18-.145.353-.142.518.004.165.067.325.189.48Z" fill="#fff"/>
              <path d="M18.836,88.577l.359-4.387,1.223.855-.354,2.922,2.659-1.31,1.164.813-3.953,1.875-1.498,2.143-1.097-.767,1.498-2.143Z" fill="#fff"/>
              <path d="M24.951,87.987l3.994,1.789-.466,1.042-2.772-1.241-.573,1.279,2.608,1.168-.47,1.05-2.608-1.168-.646,1.443,2.944,1.319-.59.987-4.043-1.811,2.622-5.856Z" fill="#fff"/>
              <path d="M32.266,90.681l1.249.262,1.043,6.773-1.407-.294-.198-1.556-2.199-.46-.805,1.346-1.354-.284,3.672-5.787ZM32.836,94.711l-.294-2.412-1.228,2.093,1.522.319Z" fill="#fff"/>
              <path d="M36.818,91.424l2.273-.068c.503-.015.919.031,1.249.138.33.107.59.252.782.435.191.183.326.389.405.617s.122.453.128.675c.012.413-.052.763-.193,1.049-.141.286-.386.53-.733.732l1.384,2.683-1.446.043-1.15-2.375-1.258.038.072,2.407-1.321.04-.192-6.414ZM39.267,94.245c.21-.006.383-.034.519-.083.136-.049.244-.115.322-.198s.131-.182.158-.297c.026-.115.038-.241.034-.379-.003-.113-.025-.222-.064-.326s-.103-.195-.192-.273c-.089-.078-.211-.138-.365-.178-.154-.04-.348-.057-.581-.05l-.925.028.054,1.787,1.042-.031Z" fill="#fff"/>
              <path d="M45.436,94.918c.129.375.325.621.588.737.263.117.606.12,1.029.009.203-.053.368-.12.496-.2.128-.08.225-.168.293-.263.068-.095.108-.197.12-.306.012-.108.003-.22-.028-.336-.042-.162-.143-.298-.302-.408-.159-.11-.409-.154-.75-.133l-.847.064c-.334.026-.635.024-.902-.005s-.504-.096-.708-.2c-.204-.104-.377-.252-.521-.444-.143-.191-.253-.435-.331-.731-.078-.296-.087-.572-.027-.829.059-.257.175-.489.347-.695.172-.206.396-.387.671-.543.275-.156.589-.28.943-.373.452-.119.842-.165,1.171-.14.329.025.608.099.838.221.23.122.42.285.569.487.149.203.27.422.363.657l-1.185.478c-.154-.325-.341-.539-.563-.642-.222-.103-.519-.105-.889-.008-.22.058-.396.124-.528.199-.132.075-.234.154-.305.238-.071.084-.113.171-.127.261-.013.09-.009.179.014.266.061.232.172.388.332.47.161.082.406.113.736.095l.895-.058c.707-.043,1.257.043,1.648.26.391.216.661.606.808,1.168.072.272.087.536.045.792-.041.256-.14.495-.294.718-.155.223-.371.423-.647.597s-.617.315-1.023.422c-.846.222-1.541.211-2.085-.033-.544-.244-.929-.727-1.156-1.448l1.313-.345Z" fill="#fff"/>
              <path d="M57.743,92.152c-.402.261-.804.429-1.205.504-.401.075-.793.061-1.173-.042-.381-.103-.743-.289-1.086-.559-.343-.27-.657-.624-.94-1.061-.284-.437-.479-.868-.586-1.291-.107-.423-.128-.831-.065-1.222.064-.391.212-.755.444-1.091.232-.336.546-.633.943-.891.402-.261.804-.429,1.205-.504.401-.075.791-.06,1.17.044.378.104.738.293,1.079.564.34.272.653.626.936,1.063.284.437.482.866.594,1.286.112.42.139.824.08,1.212-.059.388-.204.75-.436,1.086-.232.336-.551.636-.958.9ZM57.11,91.192c.472-.307.72-.696.742-1.167.023-.472-.16-1.006-.548-1.605-.385-.593-.798-.977-1.24-1.151s-.899-.108-1.371.199c-.473.307-.719.697-.74,1.171-.021.474.161,1.008.546,1.601.388.598.802.983,1.242,1.155.44.172.896.104,1.368-.202Z" fill="#fff"/>
              <path d="M57.692,84.013l.913-.98,3.853,3.59,2.071-2.222.744.889-2.885,3.097-4.694-4.374Z" fill="#fff"/>
              <path d="M61.179,80.203l1.103-2.131c.452-.872,1.04-1.429,1.766-1.671.726-.242,1.554-.121,2.485.361.474.245.853.528,1.14.848.286.32.486.672.598,1.053.113.382.135.788.067,1.219-.068.43-.221.877-.461,1.34l-1,1.931-5.698-2.95ZM66.875,80.673c.16-.309.252-.595.277-.858.025-.264-.009-.506-.104-.727s-.239-.422-.432-.603c-.193-.181-.43-.344-.713-.49-.277-.143-.542-.245-.797-.307s-.491-.071-.709-.028c-.218.043-.419.139-.605.289-.186.15-.352.366-.498.648l-.488.942,3.671,1.901.397-.766Z" fill="#fff"/>
            </g>
          </svg>
        </Link>
      </ScrollTransition>
    </div>
  )
}
